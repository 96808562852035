<template>
    <header>
        <div class="wrapper">
            <img src="https://cms.aplesports.com/storage/uploads/2020/04/23/5ea18b0a28273APL_New.png" alt="APL Esports Logo">
            <nav>
                <div class="menu" @click="openMenu()">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>

                <div class="overlay animate__animated animate__fadeIn"></div>

                <div class="links">
                    <router-link :to="{ name: 'Home' }">home</router-link>
                    <router-link :to="{ name: 'RumbleMain' }" :class="$route.name === 'RegionalRumble' && 'router-link-active' ? 'active' : ''">Regional Rumble</router-link>
                    <router-link :to="{ name: 'Nationals' }" :class="$route.name === 'Nationals' && 'router-link-active' ? 'active' : ''">nationals</router-link>
                    <!--<router-link :to="{ name: 'Kickoff' }" :class="$route.name === 'Kickoff' && 'router-link-active' ? 'active' : ''">the kickoff</router-link>-->
                    <router-link :to="{ name: 'News' }" :class="$route.name === 'News' && 'router-link-active' || $route.name === 'Article' && 'router-link-active' ? 'active' : ''">news</router-link>
                    <router-link :to="{ name: 'Tournaments' }">tournaments</router-link>
                    <router-link :to="{ name: 'Team' }">team</router-link>
                    <a href="https://discord.gg/q6Nf2eh" target="_blank">discord</a>
                    <!-- <a href="https://www.patreon.com/apl_esports" target="_blank">patreon</a> -->
                    <!-- <a href="https://donate.aplesports.com" target="_blank">donate</a> -->
                </div>

                <div class="links_mobile animate__animated animate__slideInRight">
                    <div class="close" @click="closeMenu()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/>
                        </svg>
                    </div>

                    <router-link :to="{ name: 'Home' }" @click.native="closeMenu()">home</router-link>
                    <router-link :to="{ name: 'RumbleMain' }" :class="$route.name === 'RegionalRumble' && 'router-link-active' ? 'active' : ''" @click.native="closeMenu()">Regional Rumble</router-link>
                    <router-link :to="{ name: 'Nationals' }" :class="$route.name === 'Nationals' && 'router-link-active' ? 'active' : ''" @click.native="closeMenu()">nationals</router-link>
                    <!--<router-link :to="{ name: 'Kickoff' }" :class="$route.name === 'Kickoff' && 'router-link-active' ? 'active' : ''" @click.native="closeMenu()">the kickoff</router-link>-->
  <router-link :to="{ name: 'News' }" :class="$route.name === 'News' && 'router-link-active' || $route.name === 'Article' && 'router-link-active' ? 'active' : ''" @click.native="closeMenu()">news</router-link>
                    <router-link :to="{ name: 'Tournaments' }" @click.native="closeMenu()">tournaments</router-link>
                    <router-link :to="{ name: 'Team' }" @click.native="closeMenu()">team</router-link>
                    <a href="https://discord.gg/q6Nf2eh" target="_blank" @click.native="closeMenu()">discord</a>
                    <!-- <a href="https://www.patreon.com/apl_esports" target="_blank" @click.native="closeMenu()">patreon</a> -->
                    <!--<a href="https://donate.aplesports.com" target="_blank" @click.native="closeMenu()">donate</a>-->
                </div>                
            </nav>
        </div>
    </header>
</template>

<script>
export default {
    mounted() {
        window.addEventListener('scroll', () => {
            let h = document.querySelector('header');
            if(document.body.scrollTop > 128 || document.documentElement.scrollTop > 128) {
                if(!h.classList.contains('scroll')) {
                    h.classList.add('scroll')
                }
            } else {
                if(h.classList.contains('scroll')) {
                    h.classList.remove('scroll')
                }
            }
        })
    },
    methods: {
        openMenu() {
            if(document.querySelector('.overlay').classList.contains('animate__fadeOut')) {
                document.querySelector('.overlay').classList.remove('animate__fadeOut');
            }

            if(document.querySelector('.links_mobile').classList.contains('animate__slideOutRight')) {
                document.querySelector('.links_mobile').classList.remove('animate__slideOutRight');
            }

            document.querySelector('.overlay').classList.add('animate__fadeIn');
            document.querySelector('.links_mobile').classList.add('animate__slideInRight');

            document.querySelector('body').style.overflowY = "hidden";
            document.querySelector('.overlay').style.display = "flex";
            document.querySelector('.links_mobile').style.display = "flex";
        },
        closeMenu() {
            if(document.querySelector('.overlay').classList.contains('animate__fadeIn')) {
                document.querySelector('.overlay').classList.remove('animate__fadeIn');
            }

            if(document.querySelector('.links_mobile').classList.contains('animate__slideInRight')) {
                document.querySelector('.links_mobile').classList.remove('animate__slideInRight');
            }

            document.querySelector('.overlay').classList.add('animate__fadeOut');
            document.querySelector('.links_mobile').classList.add('animate__slideOutRight');
            
            setTimeout(() => {
                document.querySelector('.overlay').style.display = "none";
                document.querySelector('.links_mobile').style.display = "none";
                document.querySelector('body').style.overflowY = "scroll";
            }, 400);
        }
    }
}
</script>
