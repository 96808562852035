<template>
  <div class="wrapping home">
    <!-- begin hero section -->
    <div class="container">
      <main>
        <div class="background-apl-text">APL</div>
        <small>Welcome To</small>
        <img src="https://cms.aplesports.com/storage/uploads/2020/04/23/5ea178d6570e1APL_New.png" alt="APL Esports Logo">
        <br>
        <big>APL Esports</big>
        <p>An independent tournament organiser that combines the experience of various community figures in Asia to produce premier tournaments.</p>
        <router-link :to="{ name: 'Team' }" class="btn">Our Team</router-link>
      </main>
    </div>
    <!-- end hero section -->

    <!-- begin featured news -->
    <section class="featured-news">
      <div class="container">
        <div class="title">featured news</div>
      </div>
      <div class="grid">
        <div class="big">
          <router-link v-if="this.posts[0].link" :to="'news/' + this.posts[0].link">
            <div class="panel" v-bind:style="{ 'background-image': 'url(' + imgRoot + this.posts[0].image.path + ')' }">
              <div class="overlay"></div>
              <div class="ptitle">{{ this.posts[0].title }}</div>
              <div class="author_name">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z"/>
                </svg> 
                {{ this.posts[0].author }}
              </div>
            </div>
          </router-link>
          <router-link v-else :to="'news/' + this.posts[0].title_slug">
            <div class="panel" v-bind:style="{ 'background-image': 'url(' + imgRoot + this.posts[0].image.path + ')' }">
              <div class="overlay"></div>
              <div class="ptitle">{{ this.posts[0].title }}</div>
              <div class="author_name">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z"/>
                </svg> 
                {{ this.posts[0].author }}
              </div>
            </div>
          </router-link>
        </div>
        <div class="inner-grid">
          <div class="small1">
            <router-link v-if="this.posts[1].link" :to="'news/' + this.posts[1].link">
              <div class="panel" v-bind:style="{ 'background-image': 'url(' + imgRoot + this.posts[1].image.path + ')' }">
                <div class="overlay"></div>
                <p class="ptitle">{{ this.posts[1].title }}</p>
                <div class="author_name">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z"/>
                  </svg> 
                  {{ this.posts[1].author }}
                </div>
              </div>
            </router-link>
            <router-link v-else :to="'news/' + this.posts[1].title_slug">
              <div class="panel" v-bind:style="{ 'background-image': 'url(' + imgRoot + this.posts[1].image.path + ')' }">
                <div class="overlay"></div>
                <p class="ptitle">{{ this.posts[1].title }}</p>
                <div class="author_name">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z"/>
                  </svg> 
                  {{ this.posts[1].author }}
                </div>
              </div>
            </router-link>
          </div>
          <div class="small2">
            <router-link v-if="this.posts[2].link" :to="'news/' + this.posts[2].link">
              <div class="panel" v-bind:style="{ 'background-image': 'url(' + imgRoot + this.posts[2].image.path + ')' }">
                <div class="overlay"></div>
                <p class="ptitle">{{ this.posts[2].title }}</p>
                <div class="author_name">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z"/>
                  </svg> 
                  {{ this.posts[2].author }}
                </div>
              </div>
            </router-link>
            <router-link v-else :to="'news/' + this.posts[2].title_slug">
              <div class="panel" v-bind:style="{ 'background-image': 'url(' + imgRoot + this.posts[2].image.path + ')' }">
                <div class="overlay"></div>
                <p class="ptitle">{{ this.posts[2].title }}</p>
                <div class="author_name">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z"/>
                  </svg> 
                  {{ this.posts[2].author }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- end featured news -->

    <!-- begin featured tournament -->
    <section class="featured-tournament">
      <div class="container">
        <div class="title">featured tournament</div>
      </div>
      <div class="tournament">
        <div class="container">
          <img :src="torRoot + this.tournaments.logo.path" alt="">
          <div class="text">
            <div class="name">{{ this.tournaments.name }}</div>
            <div class="date">Date: {{ this.tournaments.date }}</div>
            <div class="time">Time: {{ this.tournaments.time }}</div>
            <div class="pool">Prizepool: {{ this.tournaments.prize }}</div>
            <a :href="this.tournaments.link" class="btn" target="_blank">Details</a>
          </div>
        </div>
      </div>
    </section>
    <!-- end featured tournament -->

    <!-- begin tweets -->
    <section class="tweets">
      <div class="container">
        <div class="title">latest tweet</div>
        <twitter class="twitter">
          <div slot="loading">Loading...</div>
          <a class="twitter-timeline" data-width="1000" data-tweet-limit="1" data-chrome="noheader nofooter noborders transparent" data-lang="en" data-theme="dark" href="https://twitter.com/APL_Esports">@APL_Esports</a>
        </twitter>
      </div>
    </section>
    <!-- end tweets -->
  </div>
</template>

<script>
import axios from 'axios'
import { twitter } from 'vue-twitter'

export default {
  name: 'Home',
  data() {
    return {
      posts: {},
      tournaments: {},
      imgRoot: 'https://cms.aplesports.com/storage/uploads',
      torRoot: 'https://cms.aplesports.com'
    }
  },
  created() {
    axios.post("https://cms.aplesports.com/api/collections/get/News", {
      "sort": {
        "_created": -1
      },
      "limit": 3,
      "filter": {
        "published": true
      },
      "fields": {
        "title": 1,
        "image": 1,
        "title_slug": 1,
        "_by": 1,
        "link": 1
      }
    } ,{
      headers: {
        'Cockpit-Token': process.env.VUE_APP_CMS_TOKEN
      }
    })
    .then((res) => {
      this.posts = res.data.entries
    })

    axios.get("https://cms.aplesports.com/api/collections/get/Tournaments", {
        headers: {
            'Cockpit-Token': process.env.VUE_APP_CMS_TOKEN
      }
    })
    .then((res2) => {
      let tourneys = res2.data.entries
      this.tournaments = tourneys[tourneys.length - 1]
    })
  },
  components: {
	  twitter
  }
}
</script>
