import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */'../views/News.vue')
  },
  {
    path: '/news/:id',
    name: 'Article',
    component: () => import(/* webpackChunkName: "news" */'../views/Article.vue')
  },
  {
    path: '/tournaments',
    name: 'Tournaments',
    component: () => import(/* webpackChunkName: "tournaments" */'../views/Tournaments.vue')
  },
  {
    path: '/thekickoff',
    name: 'Kickoff',
    component: () => import(/* webpackChunkName: "tournaments" */'../views/Kickoff.vue')
  },
  {
    path: '/nationals',
    name: 'Nationals',
    component: () => import('../views/Nationals.vue')
  },
  {
    path: '/Nationals',
    redirect: '/nationals',
  },
  {
    path: '/nationals/register',
    name: 'Nationals Register',
    component: () => import('../views/NationalsRegister.vue')
  },
  {
    path: '/regionalrumble',
    name: 'RumbleMain',
    component: () => import('../views/RegionalRumble.vue'),
  },
  {
    path: '/regionalrumble/apac',
    name: 'RumbleApac',
    component: () => import('../views/AsianRumble.vue'),
  },
  {
    path: '/regionalrumble/mena',
    name: 'RumbleMena',
    component: () => import('../views/MERumble.vue'),
  },
  {
    path: '/regionalrumble/ssa',
    name: 'RumbleSsa',
    component: () => import('../views/AfricanRumble.vue'),
  },
  {
    path: '*',
	name: 'NotFound',
	component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
        return savedPosition;
    } else {
        return { x: 0, y: 0 }
    }
  },
})

router.beforeResolve((to, from, next) => {
  if(to.name) {
    NProgress.configure({ showSpinner: false })
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
