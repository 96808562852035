<template>
  <div id="app">
    <Header></Header>
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/Header.vue';

export default {
  components: {
    Header
  }
}
</script>

<style lang="scss">
@import './scss/main.scss';
</style>